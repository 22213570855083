// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// FontAwesome
$fa-font-path:"../webfonts";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';

// select2
@import "~select2/src/scss/core";

// bootstrap select2
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";

// flat picker
@import "~flatpickr/dist/flatpickr.min.css";

// bootstrap duallist box
@import "~bootstrap4-duallistbox/dist/bootstrap-duallistbox.min.css";

// FontAwesome CSS
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';